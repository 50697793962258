import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationJP from './locales/jap/translation.json';
import translationZH from './locales/zh/translation.json';
import translationKO from './locales/ko/translation.json';


const resources = {
  en: {
    translation: translationEN,
  },
  jap: {
    translation: translationJP,
  },
  zh: {
    translation: translationZH,
  },
  ko: {
    translation: translationKO,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'jap',
    fallbackLng: 'jap',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;