import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk for logging in the user
export const doLogin = createAsyncThunk("/user/signin", async (payload, thunkAPI) => {
  try {
    const response = await axios.post("/login", { email: payload.email, password: payload.password });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return thunkAPI.rejectWithValue({ message: 'Invalid credentials provided' });
    }
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

// Asynchronous thunk for retrieving user information using a token
export const getUser = createAsyncThunk("/tokenLogin", async (token, thunkAPI) => {
  try {
    const response = await axios.post("/token-login", { token });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

// Create a user slice with initial state and reducers
export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    user: null,
    id: null,
    error: "",
    SignUpError: "",
    token: localStorage.getItem("token") || null,
  },
  reducers: {
    // Reducer to get the token from localStorage
    getToken: (state) => {
      state.token = localStorage.getItem("token");
    },
    // Reducer to set the user state
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    // Reducer to log out the user
    logOut: (state) => {
      state.token = null;
      localStorage.removeItem("token");
      state.user = null;
      state.error = "";
    },
    // Reducer to reset error state
    resetError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state for login
      .addCase(doLogin.pending, (state) => {
        state.isLoading = true;
      })
      // Handle fulfilled state for login
      .addCase(doLogin.fulfilled, (state, { payload }) => {
        if (payload.token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${payload.token}`;
          localStorage.setItem("token", payload.token);
          state.id = payload.id;
          state.user = payload;
          state.token = payload.token;
          state.isLoading = false;
        } else {
          state.error = payload.message;
          state.isLoading = false;
        }
      })
      // Handle rejected state for login
      .addCase(doLogin.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Server error";
        state.isLoading = false;
      })
      // Handle fulfilled state for retrieving user information
      .addCase(getUser.fulfilled, (state, { payload }) => {
        if (payload.token) {
          state.user = payload;
          state.token = payload.token;
        } else {
          state.error = payload.message;
          state.isLoading = false;
        }
      });
  },
});

// Export actions from the user slice
export const { getToken, logOut, resetError, setUser } = userSlice.actions;

// Export the user slice reducer as the default export
export default userSlice.reducer;
